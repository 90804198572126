import { statesWithCodes } from 'constants/selections';
import React from 'react';
import { Card, Select, Space, DatePicker } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { StatesWithDates } from 'interfaces';
import { useFormContext } from 'react-hook-form';
import {
  setAirtableProviderLicenseDate,
  setMongoDBProviderLicenseDates,
} from 'services/api';

interface Props {
  providerId: string;
  providerEmail: string;
  providerRole: string;
  index: number;
}
const LicenseDatesPicker: React.FC<Props> = ({
  providerId,
  providerEmail,
  providerRole,
  index,
}) => {
  const { watch, setValue } = useFormContext();

  const providerLicenseDates = watch('providerLicenseDates') || [];
  const stepFiveStatesList = watch('stepFiveStatesList') || [];
  const stepFiveLicenseEffectiveDate =
    watch('stepFiveLicenseEffectiveDate') || [];
  const stepFiveLicenseExpirationDate =
    watch('stepFiveLicenseExpirationDate') || [];

  const isLegacyDates =
    !Array.isArray(stepFiveLicenseEffectiveDate) ||
    !Array.isArray(stepFiveLicenseExpirationDate);

  const formatDateToAirtable = (
    licenseDates: StatesWithDates[],
    dateType: 'effectiveDate' | 'expirationDate'
  ) => {
    // Flatten the array and process each state's data
    const formattedArray: string[] = [];

    licenseDates.forEach((item) => {
      Object.entries(item).forEach(([state, details]) => {
        if (!details[dateType]) {
          return;
        }
        formattedArray.push(`${state}: ${details[dateType]}`);
      });
    });

    // Join all formatted strings with a newline
    return formattedArray.join('\n');
  };

  const onAddedStateChange = async (value: string, index: number) => {
    setValue(`stepFiveStatesList.${index}`, value);
    let prevState;
    if (providerLicenseDates[index]) {
      prevState = Object.keys(providerLicenseDates[index])[0];
      const state = value;
      const newProviderLicenseDates = [...providerLicenseDates];
      if (newProviderLicenseDates.length <= index) {
        newProviderLicenseDates.push({});
      }
      const currentLiscenseDate = newProviderLicenseDates[index]?.[prevState];
      newProviderLicenseDates[index] = {
        [state]: {
          ...currentLiscenseDate,
        },
      };

      // setProviderLicenseDates(newProviderLicenseDates);
      setValue('providerLicenseDates', newProviderLicenseDates);
      await setMongoDBProviderLicenseDates(
        providerId,
        providerRole,
        Object.assign({}, ...newProviderLicenseDates) as StatesWithDates
      );
      await setAirtableProviderLicenseDate(
        providerEmail,
        'effective',
        formatDateToAirtable(newProviderLicenseDates, 'effectiveDate')
      );
      await setAirtableProviderLicenseDate(
        providerEmail,
        'expiration',
        formatDateToAirtable(newProviderLicenseDates, 'expirationDate')
      );
    }
  };

  const onDateChange = async (
    dateString: string,
    dateType: 'effectiveDate' | 'expirationDate',
    index: number
  ) => {
    const state = stepFiveStatesList[index];
    const newProviderLicenseDates = [...providerLicenseDates];
    if (newProviderLicenseDates.length <= index) {
      newProviderLicenseDates.push({});
    }
    const currentLiscenseDate = newProviderLicenseDates[index]?.[state];
    newProviderLicenseDates[index] = {
      [state]: {
        ...currentLiscenseDate,
        [dateType]: dateString || '',
      },
    };

    // setProviderLicenseDates(newProviderLicenseDates);
    setValue('providerLicenseDates', newProviderLicenseDates);
    const updatedStepFiveLicenseDate =
      dateType === 'effectiveDate'
        ? [...stepFiveLicenseEffectiveDate]
        : [...stepFiveLicenseExpirationDate];

    updatedStepFiveLicenseDate[index] = dateString || '';
    setValue(
      dateType === 'effectiveDate'
        ? 'stepFiveLicenseEffectiveDate'
        : 'stepFiveLicenseExpirationDate',
      updatedStepFiveLicenseDate
    );

    await setMongoDBProviderLicenseDates(
      providerId,
      providerRole,
      Object.assign({}, ...newProviderLicenseDates) as StatesWithDates
    );
    await setAirtableProviderLicenseDate(
      providerEmail,
      dateType === 'effectiveDate' ? 'effective' : 'expiration',
      formatDateToAirtable(newProviderLicenseDates, dateType)
    );
  };

  return (
    <>
      <Card
        key={index}
        title={'Certificate '}
        extra={
          <Select
            options={statesWithCodes}
            style={{ marginLeft: '10px', width: '120px' }}
            value={stepFiveStatesList[index]}
            onChange={(state) => onAddedStateChange(state, index)}
            showSearch
            optionFilterProp="label"
          />
        }
        style={{ marginTop: '10px', width: 'fit-content' }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <span>Effective Date: </span>
          <DatePicker
            disabled={!stepFiveStatesList[index]}
            onChange={(_, dateString) =>
              onDateChange(dateString as string, 'effectiveDate', index)
            }
            value={
              stepFiveLicenseEffectiveDate[index]
                ? dayjs(stepFiveLicenseEffectiveDate[index], 'YYYY-MM-DD')
                : null
            }
          />
          <span>Expiration Date: </span>
          <DatePicker
            disabled={!stepFiveStatesList[index]}
            onChange={(_, dateString) =>
              onDateChange(dateString as string, 'expirationDate', index)
            }
            value={
              stepFiveLicenseExpirationDate[index]
                ? dayjs(stepFiveLicenseExpirationDate[index], 'YYYY-MM-DD')
                : null
            }
          />
        </Space>
      </Card>
      {isLegacyDates ? (
        <TextArea
          style={{ marginTop: '10px' }}
          rows={6}
          disabled
          value={
            'Legacy Dates\n' +
            stepFiveLicenseEffectiveDate +
            '\n' +
            stepFiveLicenseExpirationDate +
            '\n'
          }
        />
      ) : null}
    </>
  );
};

export default LicenseDatesPicker;
