import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image, message, Upload, UploadFile, UploadProps } from 'antd';
import { useFormContext } from 'react-hook-form';
import { deleteCertificateFromAirtable, uploadFileURL } from 'services/api';

interface Props {
  providerEmail: string;
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

const UploadDocuments: React.FC<Props> = ({
  providerEmail,
  fileList,
  setFileList,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const { setValue } = useFormContext();

  const handlePreview = async (file: UploadFile) => {
    if (!file.thumbUrl) {
      return;
    }
    setPreviewImage(file.thumbUrl);
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({
    file,
    fileList: newFileList,
  }) => {
    if (file.status === 'done') {
      newFileList[newFileList.length - 1].uid = file.response.fileId;
      setValue('stepFiveUploads', newFileList);
      message.success(`${file.name} file uploaded successfully`);
    } else if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }
    setFileList(newFileList);
  };

  const handleRemove: UploadProps['onRemove'] = async (file) => {
    const deleteFile = await deleteCertificateFromAirtable(
      providerEmail,
      file.uid
    );
    if (deleteFile.status === 204) {
      message.success('File deleted successfully');
      return true;
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <>
      <Upload
        action={uploadFileURL(providerEmail)}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
      >
        {uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default UploadDocuments;
