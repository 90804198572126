import React, { useState } from 'react';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Divider,
  Image,
  Input,
  List,
  Select,
  Typography,
} from 'antd';
import LegalActionsChecker from 'components/LegalActionsChecker';
import { checkIfProviderFromUS, fallbackUrl } from 'helpers/utils';
import { useFormContext } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { useProviderContext } from '../ProviderContext';

const { Title, Paragraph } = Typography;

const Step3: React.FC = () => {
  const { control } = useFormContext();
  const { providerAirtableData, providerMongoDBData } = useProviderContext();
  const { watch, setValue } = useFormContext();
  const stepThreeLinks = watch('stepThreeLinks');
  const isProviderFromUS = checkIfProviderFromUS(providerMongoDBData);

  const descriptionItems: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Provider Full Name',
      labelStyle: { width: '20vw' },
      contentStyle: { width: '20vw' },
      children: (
        <>
          {providerAirtableData.providerFullName || (
            <span style={{ color: 'red' }}>Name not provided</span>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: 'Provider Identification',
      labelStyle: { width: '20vw' },
      contentStyle: { width: '20vw' },
      children: (
        <Image
          height={100}
          src={providerMongoDBData?.governmentId?.frontImage}
          fallback={fallbackUrl}
        />
      ),
    },
  ];

  if (isProviderFromUS) {
    descriptionItems.push(
      {
        key: '3',
        label: 'NPI',
        labelStyle: { width: '20vw' },
        contentStyle: { width: '20vw' },
        children: (
          <>
            {providerAirtableData.providerNpi || (
              <span style={{ color: 'red' }}>NPI not provided</span>
            )}
          </>
        ),
      },
      {
        key: '4',
        label: 'Provider State License Number',
        children: (
          <>
            {providerAirtableData.providerStateLicenseNumber || (
              <span style={{ color: 'red' }}>License number not provided</span>
            )}
          </>
        ),
      },
      {
        key: '5',
        label: 'Provider License States',
        labelStyle: { width: '20vw' },
        contentStyle: { width: '20vw' },
        children: (
          <>
            {providerMongoDBData.states?.join(', ') || (
              <span style={{ color: 'red' }}>icense states not provided</span>
            )}
          </>
        ),
      },
      {
        key: '6',
        label: 'License Types',
        labelStyle: { width: '20vw' },
        contentStyle: { width: '20vw' },
        children: (
          <>
            {providerAirtableData?.titleProviderPerformingSpecialtyProviderLicenseType?.join(
              ', '
            ) || (
              <span style={{ color: 'red' }}>License Types not provided</span>
            )}
          </>
        ),
      }
    );
  }

  const LinksComponent: React.FC = () => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    };

    const handleAddEntry = () => {
      if (inputValue.trim() !== '') {
        if (!stepThreeLinks) {
          setValue('stepThreeLinks', [inputValue]);
          setInputValue('');
          return;
        }
        setValue('stepThreeLinks', [...stepThreeLinks, inputValue]);
        setInputValue('');
      }
    };

    const handleDeleteEntry = (index: number) => {
      stepThreeLinks.splice(index, 1);
      setValue('stepThreeLinks', stepThreeLinks);
    };

    const isLink = (item: string) => {
      return (
        item.includes('.com') || item.includes('.org') || item.includes('http')
      );
    };

    return (
      <>
        <Input
          value={inputValue}
          onChange={handleInputChange}
          onPressEnter={handleAddEntry}
          placeholder="Type and press enter to add a link"
        />
        <Button
          onClick={handleAddEntry}
          type="primary"
          style={{ marginTop: '10px' }}
        >
          Add Entry
        </Button>
        <FormItem
          name="stepThreeLinks"
          control={control}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rules={[
            {
              required: true,
              message: 'You must add research links to proceed',
            },
          ]}
        >
          <List
            style={{ marginTop: '20px' }}
            bordered
            dataSource={stepThreeLinks}
            renderItem={(item: string, index) => (
              <List.Item
                actions={[
                  <Button
                    key={index}
                    onClick={() => handleDeleteEntry(index)}
                    type="text"
                    danger
                  >
                    Delete
                  </Button>,
                ]}
              >
                {isLink(item) ? (
                  <a href={item} target="_blank" rel="noreferrer">
                    {item}
                  </a>
                ) : (
                  item
                )}
              </List.Item>
            )}
          />
        </FormItem>
      </>
    );
  };

  return (
    <>
      <Typography>
        <Descriptions
          title={<h4>Provider Info</h4>}
          size="middle"
          bordered
          items={descriptionItems}
          column={1}
          style={{ width: '40vw', maxWidth: '600px', marginBottom: '20px' }}
        />
        <Divider />
        <LegalActionsChecker />
        <Divider />
        <LinksComponent />
        <Title level={4}>Tips</Title>
        <Paragraph>
          <li>
            Leverage Precision Tools: Use quotation marks for exact matches, and
            Boolean operators like AND, OR, NOT to refine your searches.
            Advanced search operators like &quot;site:&quot;,
            &quot;intitle:&quot;, and &quot;inurl:&quot; can help narrow down
            results to specific websites, titles, or URLs.
          </li>
        </Paragraph>

        <Paragraph>
          <li>
            Explore Various Combinations and Platforms: Combine the
            individual&apos;s name with different keywords like their
            profession, city, or known affiliations. Extend your search to
            social media by specifying platforms, e.g., &quot;John Doe&quot;
            site:facebook.com.
          </li>
        </Paragraph>

        <Paragraph>
          <li>
            Assess and Filter: Utilize the minus sign (-) to exclude irrelevant
            terms from your search results, and always evaluate the credibility
            of the sources.
          </li>
        </Paragraph>
      </Typography>
      <div className="center-step-actions">
        <FormItem
          name="stepThree"
          label={
            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
              How many red flags are identified ?
            </p>
          }
          control={control}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select
            style={{ width: 80 }}
            options={[
              { value: 0, label: '0' },
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
              { value: 6, label: '6' },
            ]}
          />
        </FormItem>
      </div>
    </>
  );
};

export default Step3;
