/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import {
  Descriptions,
  DescriptionsProps,
  Select,
  Space,
  Typography,
  UploadFile,
} from 'antd';
import { useFormContext } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { useParams } from 'react-router-dom';
import LicenseDatesPicker from './LicenseDatesPicker';
import UploadDocuments from './UploadDocuments';
import { useProviderContext } from '../../ProviderContext';

const { Title, Paragraph } = Typography;

const Step5: React.FC = () => {
  const { control, reset, getValues } = useFormContext();
  const { providerMongoDBData, providerAirtableData } = useProviderContext();
  const stepFiveUploadsFromAirtable =
    providerAirtableData?.licenseCertificateCopy?.map((item) => ({
      uid: item.id,
      name: item.filename,
      thumbUrl: item.thumbnails?.large?.url,
    })) || [];

  const [fileList, setFileList] = useState<UploadFile[]>(
    stepFiveUploadsFromAirtable
  );

  useEffect(() => {
    reset({ ...getValues(), stepFiveUploads: fileList });
  }, [fileList]);

  const providerRole = providerMongoDBData?.role;
  const { providerId } = useParams();
  const providerEmail =
    providerMongoDBData?.email ||
    providerAirtableData?.providerPersonalEmailId ||
    providerAirtableData?.deelEmailId ||
    providerAirtableData?.wellniteEmailId;

  const descriptionItems: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Provider Full Name',
      labelStyle: { width: '20vw' },
      contentStyle: { width: '20vw' },
      children: (
        <>
          {providerAirtableData.providerFullName || (
            <span style={{ color: 'red' }}>Name not provided</span>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: 'Provider State License Number',
      children: (
        <>
          {providerAirtableData.providerStateLicenseNumber || (
            <span style={{ color: 'red' }}>License number not provided</span>
          )}
        </>
      ),
    },
    {
      key: '3',
      label: 'Provider License States',
      children: (
        <>
          {providerMongoDBData?.states?.join(', ') || (
            <span style={{ color: 'red' }}>License states not provided</span>
          )}
        </>
      ),
    },
    {
      key: '4',
      label: 'Provider License Type',
      children: (
        <>
          {providerAirtableData?.titleProviderPerformingSpecialtyProviderLicenseType?.join(
            ', '
          ) || <span style={{ color: 'red' }}>License type not provided</span>}
        </>
      ),
    },
  ];

  return (
    <>
      <Typography>
        <Descriptions
          title={<h4>Provider License Info</h4>}
          size="middle"
          bordered
          items={descriptionItems}
          column={1}
          style={{ width: '40vw', maxWidth: '600px' }}
        />
        <Title level={4}>Certificate Details</Title>
        <UploadDocuments
          providerEmail={providerEmail}
          fileList={fileList}
          setFileList={setFileList}
        />
        <Space size={30} wrap>
          {fileList.map((_, index) => (
            <LicenseDatesPicker
              key={index}
              index={index}
              providerId={providerId || ''}
              providerEmail={providerEmail}
              providerRole={providerRole}
            />
          ))}
        </Space>
        <Title level={4}>Tips</Title>
        <Paragraph>
          <li>
            License Details: Use the practitioner&apos;s provided license number
            and full name to search for their license status, ensuring it&apos;s
            active and in good standing.
          </li>
        </Paragraph>
        <Paragraph>
          <li>
            Cross-Reference: Compare the details obtained from the state license
            board with the information provided by the practitioner, such as
            name, license number, and specialty, for consistency and accuracy.
          </li>
        </Paragraph>
      </Typography>
      <div className="center-step-actions">
        <FormItem
          name="stepFive"
          label={
            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
              How many data points are mismatching ?
            </p>
          }
          control={control}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select
            style={{ width: 80 }}
            options={[
              { value: 0, label: '0' },
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
              { value: 6, label: '6' },
            ]}
          />
        </FormItem>
      </div>
    </>
  );
};

export default Step5;
