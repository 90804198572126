import React from 'react';
import { Steps } from 'antd';
import { checkIfProviderFromUS } from 'helpers/utils';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5/Step5';
import Step6 from './Step6';
import { useProviderContext } from '../ProviderContext';

export const STEPS_COUNT = 6;

const VerificationFormSteps: React.FC<{ currentStep: number }> = ({
  currentStep,
}) => {
  const { providerMongoDBData: providerData } = useProviderContext();

  const steps = [
    {
      title: 'Step 1',
      header: 'ID & Headshot Verification',
      content: <Step1 />,
    },
    {
      title: 'Step 2',
      header: 'Check the legal action field',
      content: <Step2 />,
    },
    {
      title: 'Step 3',
      header: 'Run Google background check',
      content: <Step3 />,
    },
    {
      title: 'Step 4',
      header: 'NPI Records',
      content: <Step4 />,
    },
    {
      title: 'Step 5',
      header: 'Verify Local License',
      content: <Step5 />,
    },
    {
      title: 'Final Score',
      header: 'FINAL SCORE',
      content: <Step6 />,
    },
  ];

  const isProviderFromUS = checkIfProviderFromUS(providerData);
  if (!isProviderFromUS) {
    steps.splice(3, 2);
  }
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <Steps current={currentStep} items={items} />
      <h3 style={{ margin: 32, fontSize: 22 }}>{steps[currentStep].header}</h3>
      <div style={{ margin: 24, width: '50vw', marginInline: 'auto' }}>
        {steps[currentStep].content}
      </div>
    </>
  );
};

export default VerificationFormSteps;
